<template>
  <!-- <div class="wrapper">
    <div class="top">
      <h2>兴农管家v1.0</h2>
      <van-icon name="user-o" size="64px" />
      <h1>为农助力 贴心服务</h1>
    </div>
    <van-form @submit="onSubmit">
      <van-field
        v-model="tel"
        name="telephone"
        left-icon="phone"
        placeholder="请输入手机号"
        :rules="[{ required: true, message: '请输入手机号' }]"
        autocomplete
      />
      <van-field
        v-model="password"
        :type="'password'"
        name="pwd"
        left-icon="lock"
        placeholder="密码"
        :rules="[{ required: true, message: '请填写密码' }]"
        autocomplete
      />
      <van-field
        v-model="againPwd"
        :type="'password'"
        name="againPwd"
        left-icon="lock"
        placeholder="再次填写密码密码"
        :rules="[{ validator: pwdValidator, message: '两次密码不一致' }]"
        autocomplete
      />

      <van-field
        v-model="validaCode"
        name="validaCode"
        left-icon="comment"
        placeholder="请输入验证码"
        :rules="[{ required: true, message: '验证码不能为空' }]"
        autocomplete
      >
        <template #button>
          <van-button
            v-if="sendLoading"
            size="small"
            type="default"
            @click="sendMessage"
            native-type="button"
            disabled
            >已发送({{ countDown }}秒)</van-button
          >
          <van-button
            v-else
            size="small"
            type="default"
            @click="sendMessage"
            native-type="button"
            :disabled="btnDisable"
            >发送验证码</van-button
          >
        </template>
      </van-field>
      <van-cell class="bottom">
        <template #title>
          <van-checkbox v-model="checked" icon-size="1.5em" label-disabled>
            同意
            <a href="#">《用户协议》</a>
            和
            <a href="#">《隐私政策》</a>
          </van-checkbox>
        </template>
      </van-cell>
      <div style="margin: 32px 8px 0">
        <van-button type="primary" size="small" block :color="themeColor"
          >注册</van-button
        >
      </div>
    </van-form>
    <div class="back" @click="$router.push('/Login')">已有账号返回登录</div>
  </div> -->
  <div class="box">
    <div class="header">
      <p class="title">
        <span>兴农管家V1.0</span>
      </p>
      <p class="SecondaryTitle">
        <span> 为农助力 贴心服务 </span>
      </p>
    </div>
    <div class="login-from">
      <div class="loginFrom-top">
        <span @click="$router.push('/Login')">登录</span>
        <span id="login">注册</span>
      </div>
      <div class="loginFrom-bottom">
        <!-- 账号 -->
        <p class="inputTop">
          <input
            type="text"
            v-model="tel"
            name="telephone"
            placeholder="请输入手机号"
            :rules="[{ required: true, message: '请输入手机号' }]"
          />
          <img src="../../assets/image/login/账户.png" alt="" />
        </p>
        <!-- 密码 -->
        <p class="inputBottom">
          <input
            type="password"
            v-model="password"
            name="pwd"
            placeholder="请输入密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          />
          <img src="../../assets/image/login/密码.png" alt="" />
        </p>
        <!-- 确认密码 -->
        <p class="inputBottom1">
          <input
            v-model="againPwd"
            type="password"
            name="againPwd"
            placeholder="请再次输入密码"
            :rules="[{ validator: pwdValidator, message: '两次密码不一致' }]"
          />
          <img src="../../assets/image/login/密码.png" alt="" />
        </p>
        <!-- 验证码 -->
        <p class="inputBottom2">
          <img src="../../assets/image/Register/yanzhengma.png" alt="" />
          <input
            v-model="validaCode"
            name="validaCode"
            left-icon="comment"
            placeholder="请输入验证码"
            :rules="[{ required: true, message: '验证码不能为空' }]"
          />
        </p>
        <div class="login-button">
          <button
            v-if="sendLoading"
            size="small"
            type="default"
            @click="sendMessage"
            native-type="button"
            disabled
            style="background: none; border: none"
          >
            已发送({{ countDown }}秒)
          </button>
          <button
            v-else
            size="small"
            type="default"
            @click="sendMessage"
            native-type="button"
            style="background: none; border: none"
          >
            发送验证码
          </button>
        </div>
        <!-- 用户协议 -->
        <div class="yes">
          <span>
            <input class="checkbox" type="checkbox" v-model="checked" />
            同意<a href="#">《用户协议》</a>和<a href="#">《隐私政策》</a>
          </span>
        </div>
        <button class="button" @click="onSubmit">注册</button>
        <p class="password">
          <span @click="$router.push('/Login')">已有账号，去登录</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { throttle } from "../../service/commit";
import { sendSMS, regist } from "@/service/loginService";
import { Toast } from "vant";
export default {
  name: "Register",
  data() {
    return {
      themeColor: "#5352ed",
      password: "",
      againPwd: "",
      tel: "",
      validaCode: "",
      checked: true,
      sendLoading: false,
      countDown: 60
    };
  },
  components: {},
  methods: {
    pwdValidator(val) {
      if (val === this.password) {
        return true;
      } else {
        return false;
      }
    },
    onSubmit: throttle(function () {
      console.log("执行一次");
      if (!this.checked) {
        Toast("请勾选同意《用户政策》和《隐私协议》");
        return;
      }
      const data = {
        mobile: this.tel,
        password: this.password,
        validCode: this.validaCode,
        clientId: "client-app"
      };
      regist(data).then((res) => {
        if (res.code === 200) {
          Toast(res.message);
          this.$router.replace("/login");
        } else {
          Toast(res.message);
        }
      });
    }, 3000),
    sendMessage() {
      const myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (myreg.test(this.tel)) {
        sendSMS(this.tel).then((res) => {
          Toast(res.resp_msg);
        });
      } else {
        Toast("手机号不合法");
      }
      const timeout = setInterval(() => {
        if (this.countDown !== 0) {
          this.countDown--;
        } else {
          clearInterval(timeout);
          this.sendLoading = false;
          this.countDown = 60;
        }
      }, 1000);
    }
  }
};
</script>
<style lang="scss" scoped>
$color: #5352ed;
html,
body {
  min-height: 100vh;
}

* {
  padding: 0;
  margin: 0;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 33.3vh;
  background-image: url(../../assets/image/login/header.png);
}

.title {
  margin-top: 3.1vh;
}

.title span {
  width: 12.1875rem;
  font-size: 2.4vh;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  line-height: 10.8vh;
}

.SecondaryTitle span {
  font-size: 3.6vh;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  line-height: 5.4vh;
}

.login-from {
  flex: 1;
  margin-top: -15.3vh;
  width: 97%;
  padding: 1.5vw;
}

.loginFrom-top {
  background-color: #fff;
  margin-top: 3vh;
  height: 11.4vh;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid #fff;
  #login {
    font-size: 2.7vh;
    padding: 5px;
    border-bottom: 1px solid #5064eb;
  }
}

.loginFrom-top span {
  display: inline-block;
  font-size: 2.1vh;
  color: #1e1e1e;
  margin-top: 3.4vh;
  margin-left: 4.6vh;
}

.loginFrom-bottom {
  padding: 2.3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 62vh;
  width: 93%;
  position: relative;
}

.loginFrom-bottom p {
  width: 100%;
}

.loginFrom-bottom p input {
  box-sizing: border-box;
  width: 100%;
  background: #e9f0ff 100%;
  height: 6.6vh;
  border-radius: 3vh;
  padding: 0 6.9vh;
  border: none;
}

.inputTop img {
  width: 2.4vh;
  height: 3vh;
  position: absolute;
  left: 4vh;
  top: 2.8vh;
}

.inputBottom {
  margin-top: 2vh;
}

.inputBottom img {
  width: 2.4vh;
  height: 3vh;
  position: absolute;
  left: 4vh;
  top: 11.4vh;
}
.inputBottom1 {
  margin-top: 2vh;
}
.inputBottom1 img {
  width: 2.4vh;
  height: 3vh;
  position: absolute;
  left: 4vh;
  top: 20.1vh;
}
.inputBottom2 {
  margin-top: 2vh;
  img {
    width: 2.4vh;
    height: 3vh;
    position: absolute;
    left: 4vh;
    top: 28.5vh;
    color: #2a6ffe;
    background: none;
  }
}
.login-button {
  position: absolute;
  right: 4vh;
  top: 29vh;
}
.button {
  width: 100%;
  height: 6.6vh;
  margin-top: 4vh;
  border-radius: 3vh;
  background: linear-gradient(0deg, #2a6eff, #2c7cf9);
  color: #ffffff;
  border: none;
}

.password {
  border: none;
  width: 100%;
  margin-top: 3.3vh;
  text-align: center;
}

.password span {
  color: #2a6ffe;
  font-size: 1.8vh;
  font-family: Source Han Sans CN;
  font-weight: 400;
  text-decoration: underline;
}

.eye {
  position: absolute;
  right: 4vw !important;
}
.yes {
  margin-top: 3vh;
  text-align: left;
  span {
    width: 93vw;
    display: block;
    text-align: left !important;
  }
}
.checkbox {
  margin-right: 2vw;
  width: 4vw;
  height: 2vh;
}
</style>
