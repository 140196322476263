// 节流
//  fn为要执行的函数，time为节流时间
export function throttle(fn, time) {
    let timer = null
    time = time || 1000
    return function (...args) {
      if (timer) {
        return
      }
      const _this = this
      timer = setTimeout(() => {
        timer = null
      }, time)
      fn.apply(_this, args)
    }
  }